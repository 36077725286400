import * as actions from "./actionTypes";
import axios from "axios";


export const clearTimer = () => {
    return {
        type: actions.CLEAR_TIMER,
    }
}

export const startTimer = time => {
    return {
        type: actions.START_TIMER,
        payload: time
    }
} 
export const stopTimer = time => {
    return {
        type: actions.STOP_TIMER
    }
} 

// Add New Registration

export const addNewRegistration = (registration) => {
    return {
        type: actions.ADD_NEW_REGISTRATION,
        payload: registration
    }
}

// Delete Registration

export const deleteRegistration = (registrationId, data) => dispatch => {
    // console.log(data)
    axios.delete("//timeregistration.washworld.org/timers/" + registrationId)
        .then(response => {
            // console.log(data)
            dispatch(getRegistrations(data));
        })
        .catch(err => {
            console.log(err);
        })
}


// GET REGISTRATION

export const getRegistrations = data => dispatch => {
    // console.log(data)
    axios.get("//timeregistration.washworld.org/timers/" + data.locationId + "/" + data.startDate + "/" + data.endDate + data.companyId)
    .then(response => {
    //   console.log(response);
      dispatch(setRegistrations(response.data.response))
    //   this.props.onSetRegistrations(response.data.response)
    })
    .catch(err => {
      console.log(err)
    })
}

// Set Registrations

export const setRegistrations = registrations => {
    return {
        type: actions.SET_REGISTRATIONS,
        payload: registrations
    }
}