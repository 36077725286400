import React, { Component } from "react";

import UserItem from "../UserItem/UserItem";

import "./AdminEdit.css";

class AdminEdit extends Component {

    state = {

    }

    render() {

        const users = this.props.users.map((item, index) => {
             return <UserItem editPassword={this.props.editPassword} key={index} user={item} edit={this.props.edit} delete={this.props.delete} />
        })

        return (
            <div className="admin-edit">
                <h2>Current users</h2>
                <div className="admin-edit-input">
                    {users}
                </div>
            </div>
        )
    }

}

export default AdminEdit;