import * as actions from "../actions/actionTypes";

const initialState = {
    locations: [],
    activeLocation: {id: ""}
}

const reducer = (state = initialState, action) => {

    switch(action.type) {

        case actions.SET_NEW_LOCATIONS:
        // console.log(action.payload)
            return {
                ...state,
                locations: action.payload
            }

        case actions.SET_ACTIVE_LOCATION:
            return {
                ...state,
                activeLocation: action.payload
            }

        default:
            return state;
    }

}

export default reducer;