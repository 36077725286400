import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import "./Location.css";

class Location extends Component {

    setActiveHandler = (city, address, id) => {
        const location = { city, address, id };
        this.props.onSetActiveLocation(location);
        this.props.history.push("/registration");
    }

    componentDidMount() {
        this.props.onGetLocations()
    }

render() {

    // console.log(this.props)

    const locations = this.props.location.locations.map((item, index) => {
        return (
            <div key={index} className="location-buttons-button" onClick={() => this.setActiveHandler(item.Name, item.Address, item.Uid)} >
                <h2>{item.Name}</h2>
                <h3>{item.Address}</h3>
            </div>
        )
    })

    return (
        <div className="location">
            <h1>Choose location</h1>
            <hr></hr>
            <div className="location-buttons" >
                {locations}
            </div>
        </div>
    )
}
}

const mapStateToProps = state => {
    return {
        location: state.location
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetActiveLocation: (location) => dispatch(actions.setActiveLocation(location)),
        onGetLocations: () => dispatch(actions.getLocations())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Location);