import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import * as actions from "../../store/actions/index";


import DatePicker from "react-datepicker";
import { TimePicker } from "antd"
import 'antd/dist/antd.css'
import moment from 'moment';


 
import "react-datepicker/dist/react-datepicker.css";

import "./Manual.css";

class Manual extends Component {

    state = {
        checkIn: "",
        checkOut: "",
        date: new Date()
    }

    handleChange = date => {
        // console.log(date);
        this.setState({date});
    }

    inputHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    }

    onTimeChangedHandlerStart = (info, time) => {
        this.setState({checkIn: time});
    }
    onTimeChangedHandlerEnd = (info, time) => {
        this.setState({checkOut: time});
    }

    onSubmitHandler = () => {
        const data = {
            ...this.state,
            user : this.props.auth.user.Uid,
            location : this.props.location.activeLocation.id,
        }
        axios.post("//timeregistration.washworld.org/timers/manual", data)
            .then(res => {
                this.props.history.push("/registration")
            })
            .catch(err => {
                console.log(err)
            })

    }
    render() {

        const threeMonthsAgo = new Date();
        threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90);

        return (
            <div className="manual" >
                <h1>Manual time registration</h1>
                <hr />
                <div className="manual-options" >
                    <div className="manual-options-dropdown">
                        <p>Check-in: </p>
                        <TimePicker onChange={this.onTimeChangedHandlerStart} name="checkIn" defaultValue={moment('00:00', "HH:mm")} format={"HH:mm"}  />
                        <div className="calendar-dropdown-icon" >
                            <i className="fas fa-clock"></i>
                        </div>
                    </div>
                    <div className="manual-options-dropdown">
                        <p>Check-out:</p>
                        <TimePicker onChange={this.onTimeChangedHandlerEnd} name="checkOut" defaultValue={moment('00:00', "HH:mm")} format={"HH:mm"}  />
                        <div className="calendar-dropdown-icon" >
                            <i className="fas fa-clock"></i>
                        </div>
                    </div>
                        <div className="manual-options-dropdown manual-dropdown-calendar"  >
                        <p>Date:</p>
                            <DatePicker className="dropdown-calendar" minDate={threeMonthsAgo} maxDate={new Date()} todayButton={"I dag"} dateFormat="dd-MM-yyyy" selected={this.state.date} onChange={this.handleChange}  />
                            <div className="calendar-dropdown-icon" ><i className="fas fa-calendar"></i></div>
                        </div>
                </div>
                <button onClick={this.onSubmitHandler} className="manual-button">Confirm time registration</button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        location: state.location,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddNew: checkIn => dispatch(actions.addNewRegistration(checkIn)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Manual);
