import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions/index";

import "./CompanyCreate.css";

class CompanyCreate extends Component {

state = {
    name: "",
    vat: "",
}

changeInputHandler = (event) => {
    this.setState({[event.target.name]: event.target.value});
}

onSumbitHandler = () => {
    // this.props.submit(this.state)
    this.props.onAddNewCompany(this.state)
    this.setState({name: "", vat: ""})
}

render() {

    return (
        <div className="admin-company-create">
            <div className="admin-company-create-inputs" >
                <input placeholder="Enter company name" name="name" value={this.state.name} onChange={this.changeInputHandler} />
                <input placeholder="Enter VAT-number" name="vat" value={this.state.vat} onChange={this.changeInputHandler} />
            </div>
            <button onClick={this.onSumbitHandler} >Create new company</button>
        </div>
    )
}
}


const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddNewCompany: company => dispatch(actions.addCompanies(company))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);