import * as actions from "../actions/actionTypes";
import axios from "axios";

// Login

export const login = (user, history) => dispatch => {
    axios.post("//timeregistration.washworld.org/auth", user)
        .then(res => {
            // Set Auth Header
            axios.defaults.headers.common["Authorization"] = res.data.response.Type + " " + res.data.response.Token;
            // Get Session And User
            dispatch(setSession(res.data.response));
            dispatch(getUser(res.data.response.User_id));
            // Set Auth Cookies
            localStorage.setItem("token", res.data.response.Token);
            localStorage.setItem("user", res.data.response.User_id);
            localStorage.setItem("type", res.data.response.Type);
            history.push("/registration")
        })
        .catch(err => {
            alert(err.response.data.message);
        })
}

// Set Session

export const setSession = user => {
    return {
        type: actions.SET_SESSION,
        payload: user
    }
}

// Get User

export const getUser = (userId, history = null) => dispatch => {
    axios.get("//timeregistration.washworld.org/user/" + userId)
        .then(res => {
            dispatch(setUser(res.data.response));
            if(history) {
                history.replace("/location")
            }
            
        })
        .catch(err => {
            console.log(err);
        })
}

// Set User 

export const setUser = user => {
    return {
            type: actions.SET_USER,
            payload: user
    }
}

// Logout 
export const logout = () => {
    return {
        type: actions.LOGOUT,
    }
}