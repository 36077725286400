import React from "react";

import LocationItem from "./LocationItem/LocationItem";

import "./LocationEdit.css";

const LocationEdit = props => {

    const locations = props.locations.map((item, index) => {
        return <LocationItem item={item} key={index} delete={props.delete} />
    })

    return (
        <div className="admin-locations-edit">
            <h2>Current locations</h2>
                {locations}
        </div>
    )
}

export default LocationEdit;