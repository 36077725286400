import React, { Component } from "react";
import { connect } from "react-redux";

import "./AdminCreate.css";

class AdminCreate extends Component {

    state = {
        personNumber: "",
        password: "",
        company: "Wash world",
        firstname: "",
        lastname: "",
        activeCompany: {name: ""},
        showDropdown: false,
    }

    changeInputHandler = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    dropdownHandler = () => {
        this.setState(prevState => {
            return {
                showDropdown: !prevState.showDropdown
            }
        })
    }

    setActiveCompany = company => {
        this.setState({activeCompany: company})
    }

    render() {

        let dropDown = null;

        if(this.state.showDropdown) {
    
            const items = this.props.company.companies.map((item, index) => {
                return <li onClick={() => this.setActiveCompany(item)} key={item.Uid} >{item.Name}</li>
            })
    
            dropDown = (
                <div className="registration-dropdown-items" >
                    {items}
                </div>
            )
        }


        return (
            <div className="admin-create">
                <div>
                    <input placeholder="Enter person number(this will be the user-name)" name="personNumber" value={this.state.personNumber} onChange={this.changeInputHandler} />
                    <input placeholder="Password" name="password" value={this.state.password} onChange={this.changeInputHandler} />
                </div>
                    <div className="admin-create-info" >

                    <div className="registration-options-dropdown" onClick={this.dropdownHandler} >
                        <p>Company: <span>{this.state.activeCompany.Name ? this.state.activeCompany.Name : null}</span> </p>
                        {dropDown}
                    <div>
                        <i className="fas fa-arrow-circle-down"></i>
                    </div>
                </div>

                        <input placeholder="Enter first name" name="firstname" value={this.state.firstname} onChange={this.changeInputHandler} />
                        <input placeholder="Enter last name" name="lastname" value={this.state.lastname} onChange={this.changeInputHandler} />
                    </div>
                    <button onClick={() => this.props.submit(this.state)}>Create new user</button>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        company: state.company
    }
}

export default connect(mapStateToProps)(AdminCreate);