import * as actions from "../actions/actionTypes";

const initialState = {
    users: []
}

const reducer = (state = initialState, action) => {

    switch(action.type) {

        case actions.SET_NEW_USERS:
        // console.log(action.payload)
            return {
                ...state,
                users: action.payload
            }

        default:
            return state;
    }

}

export default reducer;