import React, { Component } from "react";
import { connect } from "react-redux";

import CompanyItem from "./CompanyItem/CompanyItem";

import "./CompanyEdit.css";

class CompanyEdit extends Component {

    render() {

        const companies = this.props.companies.map((item, index) => {
            return <CompanyItem key={index} item={item} delete={this.props.delete} />
        })

        return (
            <div className="company-edit" >
                <h2>Current companies</h2>
                {companies}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

export default connect(mapStateToProps)(CompanyEdit);