import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";


// Helper Functions
import { formatToDbDate, formatDate, secondsToHms } from "../../../util/helpers";

import * as actions from "../../../store/actions/index";

import "./Timer.css";

class Timer extends Component {

    state = {
        startDate: "",
        startDateFormatted: "",
        startTimeFormatted: "",
        endDate: "",
        endDateFormatted: "",
        endTimeFormatted: "",
        finalTime: "",
        timeDiff: ""
    }

    componentDidMount() {

      // Check Active Timer
      axios.get("//timeregistration.washworld.org/timers/activeTimer/" + this.props.auth.user.Uid)
        .then(res => {

          
          let tempStopTime = new Date();
          let timeDiff = tempStopTime - res.data.response.Checked_in_unformatted;
          timeDiff /= 1000;
          const seconds = Math.round(timeDiff);
          const secondsToHs = secondsToHms(seconds);
          const dateObject = res.data.response;
          this.setState({startDate: res.data.response.Checked_in_unformatted, timeDiff: secondsToHs});
          this.startTime(dateObject);
        })
        .catch(err => {
          // console.log(err)
        })
      }

        
    startTime = (dateObject = {}) => {

      const data = {
        user: this.props.auth.user.Uid,
        location: this.props.location.activeLocation.id,
        checkin: new Date(),
      }
      
      axios.post("//timeregistration.washworld.org/timers", data)
      .then(res => {

        // Get Cards After Start

        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        const data = {
          locationId: this.props.location.activeLocation.id,
          companyId: this.props.activeCompany.Name ? "/" + this.props.activeCompany.Name : "",
          startDate: formatToDbDate(oneWeekAgo),
          endDate: formatToDbDate(this.props.endDate)
      }

        this.props.onGetRegistrations(data);
        this.props.onStartTimer({id: res.data.response.Uid})


      })
      .catch(err => {
        console.log(err)
      })
      
      this.setState({
        timeDiff: "00:00:00",
        endDate: "",
        finalTime: "", 
        startDateFormatted: "", 
        endDateFormatted: "", 
        startTimeFormatted: "", 
        endTimeFormatted: ""
      })

      const startDate = dateObject.Checked_in ? new Date(dateObject.Checked_in_unformatted) : new Date();
      const formattedStart = formatDate(startDate);
      const formattedTimeStart = startDate.toLocaleTimeString().split(".").join(":");

      const id = dateObject.Uid ? dateObject.Uid : this.props.registration.timer.id;;

      this.props.onStartTimer({startDate, going: true, id: id });
      const timer = setInterval(() => {
      let tempStopTime = new Date();
      let timeDiff = tempStopTime - startDate;
      timeDiff /= 1000;
      const seconds = Math.round(timeDiff);
      const secondsToHs = secondsToHms(seconds);
      this.setState({timeDiff: secondsToHs})

      if(this.state.finalTime) {
        clearInterval(timer);
        return;
        }
      }, 1000)
    
      this.setState({
          going: true,
          startDate: startDate,
          startDateFormatted: formattedStart,
          startTimeFormatted: formattedTimeStart
         })
      }


    stopTime = () => {
      const endDate = new Date();
      const formattedEnd = formatDate(endDate)
      const formattedTimeEnd = endDate.toLocaleTimeString().split(".").join(":")

      this.setState({
        going: false, 
        endDate,
        finalTime: this.state.timeDiff, 
        endDateFormatted: formattedEnd,
        endTimeFormatted: formattedTimeEnd
      })

      const id = this.props.registration.timer.id;

      axios.get(`//timeregistration.washworld.org/timers/${id}/checkout`)
        .then(res => {

          const oneWeekAgo = new Date();
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  
  
          const data = {
            locationId: this.props.location.activeLocation.id,
            companyId: this.props.activeCompany.Name ? "/" + this.props.activeCompany.Name : "",
            startDate: formatToDbDate(oneWeekAgo),
            endDate: formatToDbDate(this.props.endDate)
        }
  

          this.props.onGetRegistrations(data);
        })
        .catch(err => console.log(err));
    }

    render(){

      let timer = null;
      if(this.state.going) {
        timer = (
          <div>
              <p className="registration-header-time" >{this.state.timeDiff}</p>
              <button onClick={this.stopTime} className="registration-header-end-button" >End</button>
          </div>
        )
      } else {
        timer = (
          <button onClick={this.startTime} className="registration-header-start-button">Start timer</button>
        )
      }
        return(
            <div>
                {timer}
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    registration: state.registration,
    auth: state.auth,
    location: state.location
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClearTimer: () => dispatch(actions.clearTimer()),
    onStartTimer: (time) => dispatch(actions.startTimer(time)),
    onStopTimer: () => dispatch(actions.stopTimer()),
    onGetRegistrations: (data) => dispatch(actions.getRegistrations(data))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Timer);