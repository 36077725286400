import React, { Component } from "react";

import Aux from "../../../../Hoc/Aux/Aux";


import "./EditPassword.css";

class EditModal extends Component {

    state = {
        password: "",
        repeatPassword: ""
    }

changeInputHandler = (event) => {
    this.setState({[event.target.name]: event.target.value});
}

submitHandler = () => {
    this.props.toggleModal()
    const data = {
        password: this.state.password,
        repeat_password: this.state.repeatPassword,
        id: this.props.user.Uid
    }
    this.props.editPassword(data)
}

    render() {
        // console.log(this.props)
        return (
            <Aux>
                <h2 className="edit-password-modal-edit-h2">EDIT PASSWORD</h2>
                    <div className="edit-password-modal-edit-info" >
                        <input placeholder="New Password" name="password" value={this.state.password} onChange={this.changeInputHandler} />
                        <input placeholder="Repeat New Password" name="repeatPassword" value={this.state.repeatPassword} onChange={this.changeInputHandler} />
                    </div>
                    <div className="edit-password-modal-edit-buttons">
                        <button onClick={this.props.toggleModal}>CANCEL</button>
                        <button onClick={this.submitHandler}>SAVE CHANGES</button>
                    </div>
            </Aux>
        )
    }
}

export default EditModal;