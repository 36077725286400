export const ADD_NEW_REGISTRATION = "ADD_NEW_REGISTRATION";
export const DELETE_REGISTRATION = "DELETE_REGISTRATION";
export const SET_REGISTRATIONS = "SET_REGISTRATIONS";
export const GET_REGISTRATIONS = "GET_REGISTRATIONS";

export const SET_ACTIVE_LOCATION = "SET_ACTIVE_LOCATION";

export const SET_NEW_LOCATIONS = "SET_NEW_LOCATIONS";
export const ADD_LOCATION = "ADD_LOCATION";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const DELETE_LOCATION = "DELETE_LOCATION";

export const GET_COMPANIES = "GET_COMPANIES";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const SET_NEW_COMPANIES = "SET_NEW_COMPANIES";
export const ADD_COMPANY = "ADD_COMPANY";

export const SET_NEW_USERS = "SET_NEW_USERS";

export const CLEAR_TIMER = "CLEAR_TIMER";
export const START_TIMER = "START_TIMER";
export const STOP_TIMER = "STOP_TIMER";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SET_SESSION = "SET_SESSION";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";

export const GET_USERS = "GET_USERS";