import React, { Component } from "react";
import { connect } from "react-redux";

import Aux from "../../../../Hoc/Aux/Aux";


import "./EditModal.css";

class EditModal extends Component {

    state = {
        First_name: "",
        Last_name: "",
        Username: "",
        showDropdown: false,
        activeCompany: {Name: ""}

    }

componentDidMount() {
    this.setState({
        ...this.props.user,
        activeCompany: {
            Name: this.props.user.CompanyName,
            Uid: this.props.user.CompanyUid

        }
    })
}

changeInputHandler = (event) => {
    this.setState({[event.target.name]: event.target.value});
}

submitHandler = () => {
    this.props.toggleModal()
    this.props.edit(this.state)
}

setActiveCompany = company => {
    this.setState({activeCompany: company})
}

dropdownHandler = () => {
    this.setState(prevState => {
        return {
            showDropdown: !prevState.showDropdown
        }
    })
}

render() {

    let dropDown = null;

    if(this.state.showDropdown) {

        const items = this.props.company.companies.map(item => {
            return <li onClick={() => this.setActiveCompany(item)} key={item.Uid} >{item.Name}</li>
        })

        dropDown = (
            <div className="registration-dropdown-items" >
                {items}
            </div>
        )
    }


        // console.log(this.props)
        return (
            <Aux>
                    <h2 className="user-modal-edit-h2">EDIT USER</h2>
                    <div className="user-modal-edit">
                <div>
                    <input placeholder="Enter person number(this will be the user-name)" name="Username" value={this.state.Username} onChange={this.changeInputHandler} />
                </div>
                    <div className="user-modal-edit-info" >
                    <div className="registration-options-dropdown" onClick={this.dropdownHandler} >
                        <p>Company: <span>{this.state.activeCompany.Name ? this.state.activeCompany.Name : null}</span> </p>
                        {dropDown}
                    <div>
                        <i className="fas fa-arrow-circle-down"></i>
                    </div>
                </div>
                            <input placeholder="Enter first name" name="First_name" value={this.state.First_name} onChange={this.changeInputHandler} />
                            <input placeholder="Enter last name" name="Last_name" value={this.state.Last_name} onChange={this.changeInputHandler} />
                        </div>
                    </div>
                    <div className="user-modal-edit-buttons">
                            <button onClick={this.props.toggleModal}>CANCEL</button>
                            <button onClick={this.submitHandler}>SAVE CHANGES</button>
                        </div>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        company: state.company
    }
}

export default connect(mapStateToProps)(EditModal);