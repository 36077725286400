import React, { Component } from "react";

import { connect } from "react-redux";

import { NavLink } from "react-router-dom";

import Aux from "../../Hoc/Aux/Aux";

import "./Toolbar.css";

class Toolbar extends Component {
    state = {
        showMenu: false,
        userLinks: [
            {
                handle: "Time registration",
                url: "/registration",
                image: "fas fa-clock"
            },
            {
                handle: "Manual registration",
                url: "/manual-registration",
                image: "fas fa-cog"
            }
        ],
        adminLinks: [
            {
                handle: "Time registration",
                url: "/registration",
                image: "fas fa-clock"
            },
            {
                handle: "Manual registration",
                url: "/manual-registration",
                image: "fas fa-cog"
            },
            {
                handle: "Locations",
                url: "/admin/locations",
                image: "fas fa-map-marker"
            },
            {
                handle: "Companies",
                url: "/admin/company",
                image: "fas fa-home"
            },
            {
                handle: "Users",
                url: "/admin/users",
                image: "fas fa-user"
            },
        ]
    }


    onMenuHandler = () => {
        this.setState(prevState => {
            return {
                ...prevState,
                showMenu: !prevState.showMenu
            }
        })
    }

    render() {

        const responsiveLinks = this.state.userLinks.map((item, index) => {
            return <NavLink onClick={this.onMenuHandler}  className="toolbar-header-link" key={index} to={item.url}><li><div style={{color: "#fff"}} className={"icon " + item.image} ></div>{item.handle}</li></NavLink>
        })

        const responsiveAdminLinks = this.state.adminLinks.map((item, index) => {
            return <NavLink onClick={this.onMenuHandler} className="toolbar-header-link" key={index} to={item.url}> <li><div style={{color: "#fff"}} className={"icon " + item.image} ></div>{item.handle}</li></NavLink>
        })

        const links = this.state.userLinks.map((item, index) => {
            return <NavLink className="toolbar-header-link" key={index} to={item.url}><li><div style={{color: "#fff"}} className={"icon " + item.image} ></div>{item.handle}</li></NavLink>
        })

        const adminLinks = this.state.adminLinks.map((item, index) => {
            return <NavLink className="toolbar-header-link" key={index} to={item.url}> <li><div style={{color: "#fff"}} className={"icon " + item.image} ></div>{item.handle}</li></NavLink>
        })

        let responsiveMenu = null;
        
        if(this.state.showMenu) {
            responsiveMenu = (
                <Aux>
                    <div className="responsive-toolbar-header">
                        <h2>{this.props.location.activeLocation.city ? this.props.location.activeLocation.city : null}</h2>
                        <h3>{this.props.location.activeLocation.address ? this.props.location.activeLocation.address : null}</h3>
                        <NavLink to="/location">
                            <button>Choose other location</button>
                        </NavLink>
                    </div>
                <ul className="responsive-menu" >
                    {this.props.auth.user.Is_admin === "1" ? responsiveAdminLinks : responsiveLinks}
                </ul>
                <hr/>
                </Aux>
            )
        }

        // activeClassName="toolbar-header-link-active"

        // activeClassName="toolbar-header-link-active"

        let menu = null;
        if(this.props.location.activeLocation.id !== "") {
            menu = (
                <ul className="toolbar-header-ul" >
                    {this.props.auth.user.Is_admin === "1" ? adminLinks : links}
                </ul>
            )
        }
        
        return (
            <div className="toolbar">
                <div className="toolbar-header-menu">
                    <img onClick={this.onMenuHandler} src={process.env.PUBLIC_URL + "/images/menu.png"} alt="Burger Menu Wash World" />
                </div>

                        <div className="toolbar-header">
                            <h2>{this.props.location.activeLocation.city ? this.props.location.activeLocation.city : null}</h2>
                            <h3>{this.props.location.activeLocation.address ? this.props.location.activeLocation.address : null}</h3>
                            <NavLink to="/location">
                                <button>Choose other location</button>
                            </NavLink>
                        </div>
                        <hr/>

                        {menu}
                        
                        {responsiveMenu}
                        
                        <div className="toolbar-img">
                            <img src={process.env.PUBLIC_URL + "/images/WW-logo.png"} alt="Wash World Logo" />
                        </div>
                    </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        location: state.location,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Toolbar);