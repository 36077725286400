import * as actions from "../actions/actionTypes";

const initialState = {
    companies: []
}

const reducer = (state = initialState, action) => {

    switch(action.type) {

        case actions.SET_NEW_COMPANIES:
            return {
                ...state,
                companies: action.payload
            }

        default:
            return state;
    }

}

export default reducer;