import * as actions from "./actionTypes";
import axios from "axios";

// Set Users

export const setNewUsers = users => {
    return {
        type: actions.SET_NEW_USERS,
        payload: users
    }
}


// Get Users

export const getUsers = () => dispatch => {
    axios.get("//timeregistration.washworld.org/users")
        .then(res => {
            // console.log(res);
            dispatch(setNewUsers(res.data.response))
        })
        .catch(err => {
            console.log(err);
        })
}
