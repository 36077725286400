import React, { Component } from "react";

import Aux from "../../../../Hoc/Aux/Aux";

import Backdrop from "../../../UI/Backdrop/Backdrop";
import Modal from "../../../UI/Modal/Modal";

import EditModal from "../EditModal/EditModal";
import EditPassword from "../EditPassword/EditPassword";

import "./UserItem.css";

class useritem extends Component {

state = {
    showModal: false,
    showEdit: false,
    showEditPassword: false,
    show: ""
}

toggleModalHandler = (show) => {
    this.setState(prevState => {
        return {
            showModal: !prevState.showModal,
            show: show
        }
    })
}

deleteHandler = () => {
    this.toggleModalHandler();
    this.props.delete(this.props.user)
}

    render() {
        let modalContent = null;
        if(this.state.show === "edit") {
            modalContent = <EditModal user={this.props.user} edit={this.props.edit} toggleModal={this.toggleModalHandler} />
        } else if (this.state.show === "delete"){
            modalContent = (
            <div className="admin-user-delete" >
                <h2>DELETING USER</h2>
                <p>ARE YOU SURE YOU WANT TO DELETE THE USER</p>
                <p className="admin-user-delete-user" >{this.props.user.First_name} - {this.props.user.Last_name}</p>
                <div className="admin-user-delete-buttons">
                    <button onClick={this.deleteHandler}>DELETE</button>
                    <button onClick={this.toggleModalHandler}>KEEP</button>
                </div>
            </div>
            )
        } else if (this.state.show === "password") {
            modalContent = <EditPassword editPassword={this.props.editPassword} user={this.props.user} toggleModal={this.toggleModalHandler} />
        }
        return (
            <Aux>
            <Backdrop show={this.state.showModal} >
                <Modal>
                    {modalContent}
                </Modal>
            </Backdrop>
            <div className="admin-user" >
                <p>User: <span>{this.props.user.First_name} {this.props.user.Last_name}</span></p>
                <div className="admin-user-icons" >
                <div onClick={() => this.toggleModalHandler("delete")} >
                    <i className="fas fa-trash"></i>
                </div>
                <div  onClick={() => this.toggleModalHandler("edit")}>
                    <i className="fas fa-edit"></i>
                </div>
                <div  onClick={() => this.toggleModalHandler("password")}>
                    <i className="fas fa-key"></i>
                </div>
                </div>
            </div>
            </Aux>
        )
    }
}

export default useritem;