import * as actions from "./actionTypes";
import axios from "axios";

export const setActiveLocation = location => {
    return {
        type: actions.SET_ACTIVE_LOCATION,
        payload: location
    }
}

export const setNewLocations = locations => {
    return {
        type: actions.SET_NEW_LOCATIONS,
        payload: locations
    }
}

// Add new location

export const addLocation = location => dispatch => {
    axios.post("//timeregistration.washworld.org/locations", location)
        .then(res => {
            // console.log(res);
            dispatch(getLocations());
        })
        .catch(err => {
            console.log(err);
        })
}

// Get Locations

export const getLocations = () => dispatch => {
    axios.get("//timeregistration.washworld.org/locations")
        .then(res => {
            dispatch(setNewLocations(res.data.response))
        })
        .catch(err => {
            console.log(err)
        })
    }
    
    // Delete Location
    
export const deleteLocation = id => dispatch => {
    axios.delete("//timeregistration.washworld.org/locations/" + id)
        .then(res => {
            // console.log(res);
            dispatch(getLocations());
        })
        .catch(err => {
            console.log(err);
        })
    }