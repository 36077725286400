import * as actions from "../actions/actionTypes";

const initialState = {
    authenticated: false,
    session: {},
    user: {
        Is_admin: 0
    }

}

const reducer = (state = initialState, action) => {

    switch(action.type) {

        case actions.SET_SESSION:
            return {
                ...state,
                session: action.payload,
                authenticated: true,
            }

        case actions.SET_USER:
            return {
                ...state,
                user: action.payload,
                authenticated: true,
            }

        case actions.LOGOUT:
            return {
                ...state,
                authenticated: false,
                user: {
                    Is_admin: 0
                },
                session: {}
            }

        default:
            return state;
    }

}

export default reducer;