import * as actions from "./actionTypes";
import axios from "axios";

// Get Companies

export const getCompanies = () => dispatch => {

    axios.get("//timeregistration.washworld.org/companies")
        .then(res => {
            // console.log(res)
            dispatch(setNewCompanies(res.data.response))
        })
        .catch(err => {
            console.log(err)
        })
}

export const setNewCompanies = companies => {
    return {
        type: actions.SET_NEW_COMPANIES,
        payload: companies
    }
}

// Add new Company

export const addCompanies = company => dispatch => {
    axios.post("//timeregistration.washworld.org/companies", company)
        .then(res => {
            // console.log(res);
            dispatch(getCompanies());
        })
        .catch(err => {
            console.log(err)
        })
}

// Delete Company

export const deleteCompany = id => dispatch => {
    axios.delete("//timeregistration.washworld.org/companies/" + id)
        .then(res => {
            // console.log(res);
            dispatch(getCompanies());
        })
        .catch(err => {
            console.log(err);
        })
}
