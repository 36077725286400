import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../store/actions/index";

import "./LocationCreate.css";

class CompanyCreate extends Component {

state = {
    city: "",
    address: "",
}

changeInputHandler = (event) => {
    this.setState({[event.target.name]: event.target.value});
}

submitHandler = () => {
    this.props.onAddLocation(this.state)
}


render() {

    return (
        <div className="admin-location-create">
            <div className="admin-location-create-inputs" >
                <input placeholder="Enter city" name="city" value={this.state.city} onChange={this.changeInputHandler} />
                <input placeholder="Enter street address" name="address" value={this.state.address} onChange={this.changeInputHandler} />
            </div>
            <button onClick={this.submitHandler}>Create new location</button>
        </div>
    )
}
}

const mapStatetoProps = state => {
    return {}
}

const mapDispatchtoProps = dispatch => {
    return {
        onAddLocation: location => dispatch(actions.addLocation(location))
    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(CompanyCreate);