import React, { Component } from "react";
import { connect } from "react-redux";

import axios from "axios";

import * as actions from "../../store/actions/index";

import Aux from "../../Hoc/Aux/Aux";
import Backdrop from "../../Components/UI/Backdrop/Backdrop";
import Modal from "../../Components/UI/Modal/Modal";

import "./Edit.css";

class Edit extends Component {

    state = {
        firstname: "",
        lastname: "",
        personNumber: "",
        currentPass: "",
        newPass: "",
        repeatNewPass: "",
        showModal: false
    }

    componentDidMount() {
        this.setState({
            firstname: this.props.auth.user.First_name,
            lastname: this.props.auth.user.Last_name,
            personNumber: this.props.auth.user.Username
        })
    }

    changeInputHandler = event => {
        this.setState({[event.target.name]: event.target.value})
    }

    toggleModal = () => {
        this.setState(prevState => {
            return {
                showModal: !prevState.showModal
            }
        })
    }

    submitUserChanges = () => {
        const data = {
            name: {
                first: this.state.firstname,
                last: this.state.lastname
            },
            username: this.state.personNumber
        };

        axios.put("//timeregistration.washworld.org/user/" + this.props.auth.session.User_id, data)
            .then(res => {
                this.props.onGetUser(res.data.response.Uid);
            })
            .catch(err => {
                console.log(err);
            })
    }

    submitPasswordChanges = () => {

        if(this.state.newPass === this.state.repeatNewPass) {
            const data = {
                password: this.state.newPass,
                repeat_password: this.state.repeatNewPass
            }
            axios.put("//timeregistration.washworld.org/user/" + this.props.auth.session.User_id + "/changePassword", data)
            .then(res => {
                this.props.onGetUser(res.data.response.Uid);
            })
            .catch(err => {
                console.log(err);
            })
            this.toggleModal()
        } else {
            alert("Passwords do not match")
        }

    }

    render() {

        return (
            <Aux>
                <Backdrop show={this.state.showModal}>
                    <Modal>
                        <div className="edit-password" >
                            <div className="edit-password-inputs" >
                                <input type="password" className="edit-input" name="newPass" value={this.state.newPass} onChange={this.changeInputHandler} placeholder="Enter new password" />
                                <input type="password" className="edit-input" name="repeatNewPass" value={this.state.repeatNewPass} onChange={this.changeInputHandler} placeholder="Repeat new password" />
                            </div>
                            <div className="admin-user-delete-buttons">
                                <button onClick={this.toggleModal}>GO BACK</button>
                                <button onClick={this.submitPasswordChanges} >CHANGE</button>
                            </div>
                        </div>
                    </Modal>
                </Backdrop>

            <div className="edit">
                <h1>Edit profile</h1>
                <hr/>
                <div className="edit-inputs">
                    <div className="edit-input-div">
                        <label>First name: </label>
                        <input name="firstname" onChange={this.changeInputHandler} value={this.state.firstname} /> 
                    </div>
                        <div className="edit-input-div">
                            <label>Last name: </label>
                            <input name="lastname" onChange={this.changeInputHandler} value={this.state.lastname} /> 
                        </div>
                        <div className="edit-input-div" >
                                <label>Person number: </label>
                                <input name="personNumber" onChange={this.changeInputHandler} value={this.state.personNumber} />
                        </div>
                </div>
                <div className="edit-input-buttons">
                    <button onClick={this.submitUserChanges} >Save Changes</button>
                    <button onClick={this.toggleModal}>Change Password</button>
                </div>
            </div>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetUser: id => dispatch(actions.getUser(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit);