import React, { Component } from "react";
import {connect} from "react-redux";

import { formatToDbDate } from "../../../util/helpers";

import * as actions from "../../../store/actions/index";

import Backdrop from "../../UI/Backdrop/Backdrop";
import Modal from "../../UI/Modal/Modal";
import Aux from "../../../Hoc/Aux/Aux";

import "./RegistrationCardItem.css";

class RegistrationCardItem extends Component{

    state = {
        showModal: false
    }

    toggleModalHandler = () => {
        this.setState(prevState => {
            return {
                showModal: !prevState.showModal
            }
        })
    }

    deleteHandler = (id) => {
        this.toggleModalHandler();
        
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        
        const data = {
            locationId: this.props.location.activeLocation.id,
            companyId: this.props.activeCompany.Name ? "/" + this.props.activeCompany.Name : "",
            startDate: formatToDbDate(oneWeekAgo),
            endDate: formatToDbDate(this.props.endDate)
        }
        
        this.props.onDeleteRegistration(id, data);
    }


    render() {

        let checkOut = null;
        if(this.props.item.Checked_out) {
            checkOut = this.props.item.Checked_out.split(" ")[1]
        }
        
        return (
            <Aux>

            <Backdrop show={this.state.showModal} toggle={this.toggleModalHandler} >
                <Modal>
                    <div className="registration-delete" >
                        <h2>DELETING TIME REGISTRATION</h2>
                        <p>ARE YOU SURE YOU WANT TO DELETE THE TIME REGISTRATION:</p>
                        <p>Check in: <span>{this.props.item.Checked_in}</span> &amp; check out: <span>{this.props.item.Checkout_out}</span> <span>{this.props.item.Checked_in}</span> </p>
                        <div className="registration-delete-buttons">

                            <button onClick={() => this.deleteHandler(this.props.item.Uid)}>DELETE</button>

                            <button onClick={this.toggleModalHandler} >KEEP</button>
                        </div>
                    </div>
                </Modal>
            </Backdrop>

            <div className="registration-card-item" style={{backgroundColor: !checkOut ? "#67b44b" : null}} >
                <div className="registration-card-item-head">
                    <p className="registration-card-item-head-company" >{this.props.item.CompanyName}</p>
                    {this.props.auth.user.Uid === this.props.item.UserId || this.props.auth.user.Is_admin === "1"  ? <div onClick={this.toggleModalHandler}><i className="fas fa-trash"></i></div> : null}
                    
                </div>
                <div className="registration-card-item-info">
                    <div>
                        <p className="registration-card-item-info-head">Full name:</p>
                        <p className="registration-card-item-info-data">{this.props.item.First_name} {this.props.item.Last_name}</p>
                    </div>
                    <div>
                        <p className="registration-card-item-info-head">Date</p>
                        <p className="registration-card-item-info-data">{this.props.item.Checked_in.split(" ")[0].split("-").reverse().join("-")}</p>
                    </div>
                    <div>
                        <p className="registration-card-item-info-head">Company</p>
                        <p className="registration-card-item-info-data">{this.props.item.CompanyName}</p>
                    </div>
                    <div>
                        <p className="registration-card-item-info-head">VAT-number</p>
                        <p className="registration-card-item-info-data">{this.props.item.Vat}</p>
                    </div>
                    <div>
                        <p className="registration-card-item-info-head">Check-in</p>
                        <p className="registration-card-item-info-data">{this.props.item.Checked_in.split(" ")[1]}</p>
                    </div>
                    <div>
                        <p className="registration-card-item-info-head">Check out</p>
                        <p className="registration-card-item-info-data">{checkOut}</p>
                    </div>
                </div>
            </div>
            </Aux>
        ) 
    }
}

const mapStateToProps = state => {
    return {
        location: state.location,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteRegistration: (registrationIndex, data) => dispatch(actions.deleteRegistration(registrationIndex, data)),
        onGetRegistrations: data => dispatch(actions.getRegistrations(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationCardItem);