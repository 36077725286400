import React, { Component } from "react";

import * as actions from "../../store/actions/index";

import { connect } from "react-redux";

import CompanyCreate from "../../Components/Admin/Company/CompanyCreate/CompanyCreate";
import CompanyEdit from "../../Components/Admin/Company/CompanyEdit/CompanyEdit";

import "./Company.css";

class Company extends Component {

    state = {
        companies: []
    }

    componentDidMount() {
        this.props.onGetCompanies();
    }

    submitNewHandler = (company) => {
        // const newCompanies = this.state.companies.concat(company);
        // this.setState({companies: newCompanies})
    }

    deleteHandler = (company) => {
        const newCompanies = this.state.companies.filter(item => item.id !== company.id)
        this.setState({companies: newCompanies})
    }

    finalChangesHandler = () => {
        this.props.onSetNewCompanies(this.state.companies)
    }

    render() {

    return (
        <div className="company">
            <h1>Companies</h1>
            <hr/>
            <CompanyCreate submit={this.submitNewHandler} />
            <CompanyEdit companies={this.props.company.companies} delete={this.deleteHandler} />
            {/* <button className="company-button" onClick={this.finalChangesHandler}>Save changes</button> */}
        </div>
    )
}
}

const mapStateToProps = state => {
    return {
        company: state.company
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetCompanies: () => dispatch(actions.getCompanies())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);

