import React, { Component } from "react";
import { connect } from "react-redux";

import { formatToDbDate } from "../../util/helpers";

import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";

import * as actions from "../../store/actions/index";

import Timer from "../../Components/Registration/Timer/Timer";
import RegistrationCardItem from "../../Components/Registration/RegistrationCardItem/RegistrationCardItem";

import "./Registration.css";

class Registration extends Component {

    state = {
        startDate: new Date(),
        endDate: new Date(),
        showDropdown: false,
        activeCompany: {Name: ""},
    }

    componentDidMount() {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        const data = {
            locationId: this.props.location.activeLocation.id,
            companyId: this.state.activeCompany.Name ? "/" + this.state.activeCompany.Name : "",
            startDate: formatToDbDate(oneWeekAgo),
            endDate: formatToDbDate(this.state.endDate)
        }
        // console.log(data);

        this.props.onGetRegistrations(data);
        this.props.onGetCompanies();

        this.setState({startDate: oneWeekAgo});
    }

    inputHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    }

    handleStartChange = date => {
        // console.log(date)

        const data = {
            locationId: this.props.location.activeLocation.id,
            companyId: this.state.activeCompany.Name ? "/" + this.state.activeCompany.Name : "",
            startDate: formatToDbDate(date),
            endDate: formatToDbDate(this.state.endDate)
        }
        // console.log(data);

        this.props.onGetRegistrations(data);

        this.setState({startDate: date});
    }
    handleEndChange = date => {

        const data = {
            locationId: this.props.location.activeLocation.id,
            companyId: this.state.activeCompany.Name ? "/" + this.state.activeCompany.Name : "",
            startDate: formatToDbDate(this.state.startDate),
            endDate: formatToDbDate(date)
        }
        // console.log(data);

        this.props.onGetRegistrations(data);

        this.setState({endDate: date});
    }

    addNewCheckIn = (item) => {
        this.props.onAddNewRegistration(item);
    }

    dropdownHandler = () => {
        this.setState(prevState => {
            return {
                showDropdown: !prevState.showDropdown
            }
        })
    }

    onDropdownItemClick = company => {
        // console.log(company)
        this.setActiveCompany(company);
        const data = {
            locationId: this.props.location.activeLocation.id,
            companyId: company.Name ? "/" + company.Uid : "",
            startDate: formatToDbDate(this.state.startDate),
            endDate: formatToDbDate(this.state.endDate)
        }
        // console.log(data);

        this.props.onGetRegistrations(data);

    }

    setActiveCompany = company => {
        this.setState({activeCompany: company});
    }

    exportCsv = () => {

        // console.log(this.props.location.activeLocation.id)
        
        axios.get("//timeregistration.washworld.org/timers/" + this.props.location.activeLocation.id)
            .then(res => {
                // console.log(res);

                const csvRow = [];
                const A = [["firstname", "lastname", "company", "vat-number","location", "check-in", "check-out"]]
                const data = res.data.response;
        
                data.forEach(item => {
                    A.push([item.First_name, item.Last_name, item.CompanyName, item.Vat, item.LocationName, item.Checked_in,  item.Checked_out])
                });
        
                A.forEach(item => {
                    csvRow.push(item.join(";"))
                });
        
                let csvString = csvRow.join("\r\n");
                const a_tag = document.createElement("a");
                // console.log(csvString);
                csvString = encodeURI(csvString);
                a_tag.href = "data:attachment/csv," + csvString;
                a_tag.target = "_Blank";
                a_tag.download =  this.props.location.activeLocation.city + "_" + new Date().toLocaleDateString() + "_check_in.csv";
                document.body.appendChild(a_tag);
                a_tag.click();

            })
            .catch(err => {
                console.log(err);
            })
        }

    render() {

    const cards = this.props.registration.checkIns.map((item, index) => {
        return <RegistrationCardItem endDate={this.state.endDate} activeCompany={this.state.activeCompany} item={item} key={index} index={index} />
    })


    let dropDown = null;

    if(this.state.showDropdown) {

        const items = this.props.company.companies.map((item, index) => {
            return <li onClick={() => this.onDropdownItemClick(item)} key={item.Vatnumber} >{item.Name}</li>
        })

        dropDown = (
            <div className="registration-dropdown-items" >
                <li onClick={() => this.onDropdownItemClick({name: ""})}>Show all</li>
                {items}
            </div>
        )
    }


    return (


        <div className="registration">
            <div className="registration-header">
                <h1>Time Registration</h1>
                <div>
                    <Timer endDate={this.state.endDate} activeCompany={this.state.activeCompany} addNew={this.addNewCheckIn} />
                </div>
            </div>
            <hr />

            <div className="registration-options">
                <div className="registration-options-dropdown" onClick={this.dropdownHandler} >
                    <p>Company: <span>{this.state.activeCompany.Name ? this.state.activeCompany.Name : "Show all"}</span> </p>
                    {dropDown}
                    <div>
                        <i className="fas fa-arrow-circle-down"></i>
                    </div>
                </div>

                    <div className="registration-options-dropdown manual-dropdown-calendar">
                        <p>Range:</p>
                        <DatePicker className="dropdown-calendar" dateFormat={"dd-MM-yyyy"}  selected={this.state.startDate} selectsStart startDate={this.state.startDate} endDate={this.state.endDate} onChange={this.handleStartChange} />
                        <DatePicker className="dropdown-calendar" dateFormat={"dd-MM-yyyy"} selected={this.state.endDate} selectsEnd startDate={this.state.startDate} endDate={this.state.endDate} onChange={this.handleEndChange} />
                    </div>

                <div onClick={this.exportCsv} className="registration-excel" >
                    <p>Extract as CSV</p>
                    <i style={{color: "#fff"}} className="download-icon fas fa-download"></i>
                </div>
            </div>

            <div className="registration-cards">
                {cards}
            </div>
        </div>
    )
}
}

const mapStateToProps = state => {
    return {
        registration: state.registration,
        company: state.company,
        location: state.location,
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAddNewRegistration: registration => dispatch(actions.addNewRegistration(registration)),
        onGetCompanies: () => dispatch(actions.getCompanies()),
        onGetRegistrations: data => dispatch(actions.getRegistrations(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);