import React, { Component } from 'react';
// import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import { getUser } from "./store/actions/index";

import { withRouter } from "react-router-dom";

import axios from "axios";

import store from "./store/store"

import Layout from "./Hoc/Layout/Layout";
import Login from "./Containers/Login/Login";
import Admin from "./Containers/Admin/Admin";
import Registration from './Containers/Registration/Registration';
import Location from "./Containers/Location/Location";
import Manual from "./Containers/Manual/Manual";
import Edit from "./Containers/Edit/Edit";
import Company from "./Containers/Company/Company";
import AdminLocation from "./Containers/AdminLocation/AdminLocation";

import './App.css';




class App extends Component {
  
  componentDidMount() {
    // Check for token 
    if (localStorage.token && localStorage.user && localStorage.type) {
      // Set Auth Header
      axios.defaults.headers.common["Authorization"] = localStorage.type + " " + localStorage.token;
      // Get User
      store.dispatch(getUser(localStorage.user, this.props.history));
    }
  }


  render() {

    console.clear();

      let routes = (
        <Switch>
          <Route path="/login" component={Login} />
          <Redirect path='*' to="/login" />
        </Switch>
      );

      if(store.getState().auth.authenticated) {
        routes = (
          <Switch>
            <Route path="/location" component={Location} />
            <Route path="/edit" component={Edit} />
            <Redirect path='*' exact to="/location" />
          </Switch>
          )
      }
      
      // store.getState().auth.authenticated && store.getState().location.activeLocation.id

      if(store.getState().auth.authenticated && store.getState().location.activeLocation.id) {
        routes = (
          <Switch>
            <Route path="/admin/users" component={Admin} />
            <Route path="/admin/company" component={Company} />
            <Route path="/location" component={Location} />
            <Route path="/registration" component={Registration} />
            <Route path="/manual-registration" component={Manual} />
            <Route path="/edit" component={Edit} />
            <Route path="/admin/locations" component={AdminLocation} />
            <Route path="/" exact component={Location} />
            <Redirect path='*' exact to="/registration" />
          </Switch>
        )
      }
    return (
      <Layout>
            {routes}
      </Layout>
    );
  }
}
export default withRouter(App);