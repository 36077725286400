import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import locationReducer from "./reducers/location";
import companyReducer from "./reducers/company";
import userReducer from "./reducers/user";
import registrationReducer from "./reducers/registration";
import authReducer from "./reducers/auth";

const rootReducer = combineReducers({
    location: locationReducer,
    company: companyReducer,
    user: userReducer,
    registration: registrationReducer,
    auth: authReducer
})

const initialState = {};

const middleware = [thunk];

const composeEnhancers = process.env.NODE_ENV === "development" ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;

const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
        applyMiddleware(...middleware)
    )
)

export default store;