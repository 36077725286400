export const formatToDbDate = (time) => {
    let dd = time.getDate();
    let mm = time.getMonth() + 1; // January is 0!
  
    const yyyy = time.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    } 
    const today = yyyy + '-' + mm + '-' + dd;
    return today;
  }


 export const formatDate = (time) => {
    let dd = time.getDate();
    let mm = time.getMonth() + 1; // January is 0!
  
    const yyyy = time.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    } 
    const today = dd + '-' + mm + '-' + yyyy;
    return today;
  }

  export const secondsToHms = d => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
  }