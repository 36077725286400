import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import * as actions from "../../store/actions/index";


import "./Login.css";

class Login extends Component {

    state = {
        username: "",
        password: ""
    }

    changeInputHandler = event => {
        this.setState({[event.target.name]: event.target.value})
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.props.onLogin(this.state, this.props.history);
    }

    

render() {

    return (
        <div className="login">
            <div className="login-img">
                <img src={process.env.PUBLIC_URL + "/images/WW-logo-black.png"} alt="Wash World Logo" />
            </div>
            <form>
                <input placeholder="User name (Person number)" name="username" value={this.state.username} onChange={this.changeInputHandler} />
                <input placeholder="Password" type="password" name="password" value={this.state.password} onChange={this.changeInputHandler} />
            <button type="submit" onClick={(event) => this.submitHandler(event)}>Login</button>
            </form>
        </div>
    )
}
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (user, history) => dispatch(actions.login(user, history)),
        onGetUser: userId => dispatch(actions.getUser(userId))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));