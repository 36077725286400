import React, { Component } from "react";
import {connect} from "react-redux";
import axios from "axios";

import * as actions from "../../store/actions/index";

import AdminCreate from "../../Components/Admin/User/AdminCreate/AdminCreate";
import AdminEdit from "../../Components/Admin/User/AdminEdit/AdminEdit";

import "./Admin.css";

class Admin extends Component {

    state = {
        users: []
    }

    componentDidMount() {
        this.props.onGetCompanies();
        this.props.onGetUsers();
        this.setState({
            users: this.props.user.users
        })
    }

    submitNewHandler = (user) => {

        const data = {
            username: user.personNumber,
            name: {
                first: user.firstname,
                last: user.lastname,
            },
            password: user.password,
            company: user.activeCompany.Uid
        }

        // console.log(data);
        axios.post("//timeregistration.washworld.org/user", data)
            .then(res => {
                // console.log(res);
                this.props.onGetUsers();
            })
            .catch(err => {
                console.log(err);
            })
    }

    deleteHandler = (user) => {
        // console.log(user)
        axios.delete("//timeregistration.washworld.org/user/" + user.Uid)
        .then(res => {
            // console.log(res);
            this.props.onGetUsers();
        })
        .catch(err => {
            console.log(err);
        })
    }
    
    editPasswordHandler = (data) => {
        // console.log(data);
        axios.put("//timeregistration.washworld.org/user/" + data.id + "/changePassword", data)
        .then(res => {
            // console.log(res);
            // this.props.onGetUsers();
        })
        .catch(err => {
            console.log(err);
        })
    }
    
    editHandler = (user) => {
        // console.log(user)
        const data = {
            username: user.Username,
            name: {
                first: user.First_name,
                last: user.Last_name,
            },
            company: user.activeCompany.Uid
        }
        // console.log(data)
        axios.put("//timeregistration.washworld.org/user/" + user.Uid, data)
            .then(res => {
                // console.log(res)
                this.props.onGetUsers();
            })
            .catch(err => {
                console.log(err)
            })
    }

    finalChangesHandler = () => {
        this.props.onSetNewUsers(this.state.users)
    }

    render() {

        return (
            <div className="admin">
                <h1>Users</h1>
                <hr/>
                <AdminCreate submit={this.submitNewHandler} />
                <AdminEdit editPassword={this.editPasswordHandler} users={this.props.user.users} edit={this.editHandler} delete={this.deleteHandler} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetNewUsers: users => dispatch(actions.setNewUsers(users)),
        onGetCompanies: () => dispatch(actions.getCompanies()),
        onGetUsers: () => dispatch(actions.getUsers())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);