import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import axios from "axios";

import * as actions from "../../store/actions/index";

import "./Layout.css";

import Aux from "../Aux/Aux";
import Toolbar from "../../Components/Toolbar/Toolbar";

class Layout extends Component{

    state = {
        showDropdown: false
    }

    dropdownHandler = () => {
        this.setState(prevState => {
            return {
                showDropdown: !prevState.showDropdown
            }
        })
    }

    logoutHandler = () => {
        this.props.onLogout();
        this.dropdownHandler();
        axios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("type");
        this.props.history.push("/login");
    }


    render() {

        let dropdown = null;

        if(this.state.showDropdown) {
            dropdown = (
            <div className="wrapper-user-info-dropdown" >
                <ul>
                    <li onClick={this.logoutHandler} ><p>Logout</p></li>
                    <li><NavLink onClick={this.dropdownHandler} to="/edit"><p>Edit Profile</p></NavLink></li>
                </ul>
            </div>
            )
        }


        let headerInfo = null;
        if((this.props.location.pathname !== "/login")) {
            headerInfo = (
                <div className="wrapper-user-info" >
                <p>Logged in as: <span>{this.props.auth.user.First_name} {this.props.auth.user.Last_name}</span></p>
                <div onClick={this.dropdownHandler} >
                    <i className="fas fa-arrow-circle-down"></i>
                </div>
                    {dropdown}
            </div>
        )
    }
    
    return (
        <Aux>
            <div className="wrapper" >
                {this.props.location.pathname !== "/login" ? <Toolbar/> : null}
                <main>
                    {headerInfo}
                    <hr/>
                    {this.props.children}
                </main>
            </div>
        </Aux>
    )
}
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));