import React, {Component} from "react";
import { connect } from "react-redux";

import * as actions from "../../../../../store/actions/index";

import Aux from "../../../../../Hoc/Aux/Aux";

import Backdrop from "../../../../UI/Backdrop/Backdrop";
import Modal from "../../../../UI/Modal/Modal";


import "./CompanyItem.css";

class CompanyItem extends Component {

    state = {
        showModal: false
    }
    
    toggleModalHandler = () => {
        this.setState(prevState => {
            return {
                showModal: !prevState.showModal
            }
        })
    }

    deleteHandler = () => {
        this.toggleModalHandler();
        this.props.onDeleteCompany(this.props.item.Uid);
    }

    render() {

        return (
            <Aux>
                <Backdrop show={this.state.showModal} >
                <Modal>
                    <div className="admin-company-delete" >
                        <h2>DELETING COMPANY</h2>
                        <p>ARE YOU SURE YOU WANT TO DELETE THE COMPANY</p>
                        <p className="admin-company-delete-company" >{this.props.item.Name} - {this.props.item.Vatnumber}</p>
                        <div className="admin-company-delete-buttons">
                            <button onClick={this.deleteHandler}>DELETE</button>
                            <button onClick={this.toggleModalHandler} >KEEP</button>
                        </div>
                    </div>
                </Modal>
                </Backdrop>

                <div className="admin-company-item" >
                <div className="admin-company-item-company">
                    <p>Company: <span>{this.props.item.Name}</span></p>
                </div>
                <div className="admin-company-item-vat" >
                    <div className="admin-company-item-vat-vat">  
                        <p>VAT-number: <span>{this.props.item.Vatnumber}</span></p>
                    </div>
                    <div onClick={this.toggleModalHandler}>
                        <i className="fas fa-trash"></i>
                    </div>
                </div>
            </div>
        </Aux>
    )
}
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteCompany: id => dispatch(actions.deleteCompany(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyItem);