import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import LocationCreate from "../../Components/Admin/Location/LocationCreate/LocationCreate";
import LocationEdit from "../../Components/Admin/Location/LocationEdit/LocationEdit";

import "./AdminLocation.css";

class AdminLocation extends Component {

    state = {
        locations: []
    }

    componentDidMount() {
        this.props.onGetLocations()
    }

    deleteHandler = (location) => {
        const newLocations = this.props.locations.filter(item => item.id !== location.id)
        this.setState({locations: newLocations})
    }

    render() {

        return (
            <div  className="admin-location">
                <h1>Locations</h1>
                <hr/>
                <LocationCreate />
                <LocationEdit locations={this.props.location.locations} delete={this.deleteHandler} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        location: state.location
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetLocations: () => dispatch(actions.getLocations())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLocation);