import * as actions from "../actions/actionTypes";

const initialState = {
    checkIns: [],
    timer: {}
}

const reducer = (state = initialState, action) => {

    switch(action.type) {

        case actions.CLEAR_TIMER:
            return {
                ...state,
                timer: {
                    going: false,
                    startDate: "",
                }
            }

        case actions.START_TIMER:
        // console.log(action.payload)
            return {
                ...state,
                timer: action.payload
            }

        case actions.STOP_TIMER:
            return {
                ...state,
                timer: {
                    ...state.timer,
                    startDate: "",
                    going: false
                }
            }

        // Add and Delete Registrations

        case actions.ADD_NEW_REGISTRATION:
            return {
                ...state,
                checkIns: state.checkIns.concat(action.payload)
            }
        
        case actions.DELETE_REGISTRATION:

            // console.log(action.payload);
            return {
                ...state
            }
            // const newState = state.checkIns.filter((checkIn => checkIn.info.id !== action.payload ))
            // console.log(newState)
            // return {
            //     ...state,
            //     checkIns: newState

            // }
        case actions.SET_REGISTRATIONS:
            return {
                ...state,
                checkIns: action.payload
            }

        default:
            return state;
    }

}

export default reducer;