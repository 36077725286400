import React,{ Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../../store/actions/index";

import Aux from "../../../../../Hoc/Aux/Aux";

import Backdrop from "../../../../UI/Backdrop/Backdrop";
import Modal from "../../../../UI/Modal/Modal";

import "./LocationItem.css";

class locationitem extends Component {

    state = {
        showModal: false
    }
    
    toggleModalHandler = () => {
        this.setState(prevState => {
            return {
                showModal: !prevState.showModal
            }
        })
    }

    deleteHandler = () => {
        this.toggleModalHandler();
        // this.props.delete(this.props.item);
        this.props.onDeleteLocation(this.props.item.Uid);
    }

render() {

    // console.log(this.props.item)

    return (
        <Aux>
        <Backdrop show={this.state.showModal} >
            <Modal>
                <div className="admin-location-delete" >
                    <h2>DELETING LOCATION</h2>
                    <p>ARE YOU SURE YOU WANT TO DELETE THE LOCATION</p>
                    <p className="admin-location-delete-location" >{this.props.item.Name}-{this.props.item.Address}</p>
                    <div className="admin-location-delete-buttons">
                        <button onClick={this.deleteHandler}>DELETE</button>
                        <button onClick={this.toggleModalHandler} >KEEP</button>
                    </div>
                </div>
            </Modal>
        </Backdrop>

        <div className="admin-location-item" >

            <div className="admin-location-item-location">
                <p>City: <span>{this.props.item.Name}</span></p>
            </div>
            <div className="admin-location-item-street" >
                <div className="admin-location-item-street-street">  
                    <p>Street: <span>{this.props.item.Address}</span></p>
                </div>
                <div onClick={this.toggleModalHandler} >
                    <i className="fas fa-trash"></i>
                </div>
            </div>
        </div>
        </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteLocation: id => dispatch(actions.deleteLocation(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(locationitem);